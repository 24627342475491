
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiContacts } from "@/core/api";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "merchants-contacts",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const salesChannelsTableData = ref([]);
    const loading = ref<boolean>(false);
    const getCompanyChanelData = async () => {
      loading.value = true;
      const { data } = await ApiContacts.getContactList({
        page: 1,
        page_size: 999999,
        sort_orders: [{ field: "id", direction: "desc" }],
        filter_group: [{ field: "merchant_id", value: route.params.id }],
      });

      loading.value = false;
      if (data.code == 0) {
        console.log(data.data.items);
        salesChannelsTableData.value = data.data.items;
        console.log(salesChannelsTableData);
      }
    };

    const backToList = () => {
      if (route.path.indexOf("offline-merchants") != -1) {
        router.push("/merchant-network/offline-merchants");
      } else {
        router.push("/merchants");
      }
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getCompanyChanelData();
    });
    return {
      t,
      loading,
      salesChannelsTableData,
      backToList,
    };
  },
});
